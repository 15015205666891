.xcolumns {
	.uk-container {
		@include flex(row, center, stretch, nowrap);
		.col {
			flex: 1;
		padding: 50px 75px 25px 0;
			&:nth-child(2) {
				border-left: 4px solid $green;
				padding: 50px 0 25px 75px;
			}
			ul {
				li {
					padding: 0 0 20px;
					color: $navy;
					line-height: 2;
				}
			}
		}
	}
}