$black:  #000000;
$white:  #FFFFFF;
$silver: #C0C0C0;
$green:  #ACCE14;
$navy:   #253259;
$blue:   #3889F2;
$lblue:  #41A0F2;
$ice:    #B3ECF2;
$red:    #BF1F1F;

$primary: $black;
$secondary: $silver;

$font_headings:  'Playfair Display', serif;
$font_copy: 'Open Sans', sans-serif;

$font_size: 18px;

$radius: 10px;

@import "_mixins.scss", "_animations.scss";



