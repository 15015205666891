.spotlights {
	.uk-container {
		@include flex(row, flex-start, center, nowrap);
		.text {
			width: 100%;
			max-width: 500px; 
			margin-right: 100px;
		}
		.image {
			width: calc(100% - 500px);
			height: 500px;
			.background {
				position: relative;
				border-radius: $radius;
				overflow: hidden;
				height: 100%;
				widht: 100%;
				@extend %box-medium;
			}
		}
	}
	
	@media screen and (max-width: 992px) {
		.uk-container {
			flex-direction: column;
			.text {
				max-width: none;
				margin-right: 0px;
				padding: 0 0 100px;
			}
			.image {
				width: 100%;
			}
		}
	}
	
	@media screen and (max-width: 650px) {
		.uk-container {
			.image {
				height: 350px;
			}
		}
	}
	
}
