footer {
	background: $navy;
	padding: 30px 0;
	border-top: 10px solid $ice;
	* {
		color: $white;
	}
	.uk-container {
		@include flex(row, flex-start, center, nowrap);
	}
	div {
		padding: 0 30px;
		a {
			display: block;
			text-decoration: none;
			&:hover, &:active, &:focus {
				color: $ice;
			}
		}
	}
	h2 {
		margin: 0;
		line-height: 1;
	}
	
	@media screen and (max-width: 650px) {
		.uk-container {
			flex-direction: column;
			div {
				padding: 30px 0 0;
				text-align: center;
			}
		}
	}
}