header {
	padding: 10px 0;
	width: 100% !important;
	//background: $white;
	position: absolute;
	z-index: 99999;
	.uk-container {
		@include flex(row, space-between, center, nowrap);
		.logo {
			font-size: 2em;
			font-family: $font_headings;
			font-weight: bold;
			a {
				color: $blue;
				display: block;
				span {
					color: $navy;
				}
				&:hover, &:active, &:focus {
					text-decoration: none;
					span {
						color: $blue;
					}
				}
			}
		}
		nav {
			ul {
				@include flex(row, flex-end, center, nowrap);
				list-style: none;
				margin: 0;
				padding: 0;
				li {
					line-height: 1;
					a {
						padding: 0 10px;
						text-transform: uppercase;
						font-weight: bold;
						color: $navy;
						font-size: 1em;
						line-height: 1;
						&:hover, &:active, &:focus {
							color: $blue;
						}
					}
				}
			}
		}
	}
	&.uk-active {
		@extend %box-light;
		background: $white;
	}
	@media screen and (max-width: 787px) {
		.uk-container {
			justify-content: center;
			nav {
				display: none;
			}
		}
	}
}