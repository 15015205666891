* { box-sizing: border-box; }
html, body {width: 100%; font-size: $font_size; font-family: $font_copy} //default $font_size is declared in _variables.scss
em { color: inherit; } //removes uikit em red style and replaces it with theme style
p a, li a { text-decoration: none !important;} //removes pesky text-decorations
.MarkupSocialShareButtons {@extend .uk-iconnav}
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
// ---- Project Styles Begin Here ---- //

body {
	background-blend-mode: multiply;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right top;
}


h1, h2, h3 {
	font-family: $font_headings;
	line-height: 1.1;
}

h2 {
	color: $navy;
}


main {
	padding: 100px 0;
	min-height: calc(100vh - 198px);
	h1 {
		color: $navy;
		margin: 0;
	}
	h2 {
		color: $blue;
		margin: 10px 0 0;
		font-family: $font_copy;
		text-transform: none;
		font-weight: normal;
	}
	time {
		color: $blue;
		text-transform: uppercase;
		display: block;
		margin: 25px 0 50px;

		
	}
	a {
		color: $blue;
		&:hover, &:focus, &:active {
			color: $navy;
		}
	}
	p, li {
		line-height: 1.75;
		color: $black;
	}
}

.MarkupSocialShareButtons {
	width: 100%;
	justify-content: center;
	li {
		a {
			color: $black;
			&:hover, &:active, &:focus {
				color: $blue;
			}
		}
	}
}

.template-grid {
	main {
		h1 {
			text-align: center;
		}
		ul {
			@include flex(row, center, stretch, wrap);
			list-style: none;
			margin: 50px 0;
			padding: 0;
			li {
				width: calc(100% / 3);
				padding: 15px;
				min-width: 300px;
				.box {
					padding: 020px;
					background: $white;
					text-align: left;
					height: 100%;
					width: 100%;
					border-radius: $radius;
					overflow: hidden;
					position: relative;
					@extend %box-light;
					h3 {
						color: $navy;
						margin: 0;
					}
					.time {
						color: $blue;
						display: block;
						font-size: 0.8em;
						font-weight: bold;
						text-transform: uppercase;
						padding: 10px 0;
					}
					p {
						color: $black;
						font-size: 0.7em;
						line-height: 1.7;
						margin: 0;
					}
				}
				a {
					&:hover, &:active, &:focus {
						.box {
							background: rgba($ice, 0.4);
							
						}
					}
				}
				@media screen and (max-width: 650px) {
					min-width: 0px;
					width: 100%;
				}
			}
		}
	}
}

#say {
	.uk-container {
		.uk-slider {
			ul {
				padding: 40px 0 60px !important;
				li {
					width: 100%;
					padding: 0 25px !important;
					.box {
						padding: 20px;
						background: $white;
						text-align: center;
						@extend %box-none;
						@include flex(column, center, center, nowrap);
						h3 {
							color: $navy;
							margin: 30px 0 0;
							order: 2;
						}
						h4 {
							color: $blue;
							display: block;
							font-size: 0.8em;
							font-weight: bold;
							text-transform: uppercase;
							padding: 10px 0;
							order: 3;
							margin: 0;
						}
						p {
							color: $black;
							font-size: 1.4em;
							line-height: 1.7;
							margin: 0;
						}
					}
					a {
						&:hover, &:active, &:focus {
							.box {
								background: rgba($ice, 0.4);
								
							}
						}
					}
				}
			}
		}
	}	
	@media screen and (max-width: 992px) {
		.uk-container .uk-slider {
			ul {
				li .box {
					p {
						font-size: 0.9em;
					}
					h3 {
						font-size: 1.1em;
					}
				}
			}
		}
	}
}