.template-default {
	main {
		img {
			border-radius: 20px;
		}
		h1 {
			margin: 50px 0 20px;
			& + h2 {
				text-transform: none;
				margin: 0 0 50px;
			}
		}
		hr {
			margin: 40px 0;
		}
		h2 {
			font-size: 2em;
			text-transform: uppercase;
		}
		h3 + p strong {
			color: $blue;
		}
	}
	&#page1233 {
		main {
			h1 {
				text-align: center;
				& + h2 {
					text-align: center;
					max-width: 700px;
					margin: 0 auto 50px;
					& + p img, img {
						@extend %box-medium;
						margin: 100px auto;
						display: block;
					}
				}
			}
		}
	}
}