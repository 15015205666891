#hero {
	height: 100vh;
	min-height: 800px;
	position: relative;
	.background {
		position: absolute;
		opacity: 0.3;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		ul {
			height: 100%;
		}
	}
	.container {
		background: rgba($white, 0.6);
		background: linear-gradient( rgba($white, 0.5) 0%, rgba($white, 0.5) 90%, rgba($white, 1) 100%);
		width: 100%;
		height: 100%;
		z-index: 1;
		position: relative;
	}
	.uk-container {
		height: 100%;
		@include flex(row,center, center, nowrap);
		.text {
			text-align: center;
			max-width: 700px;
			@include flex(column,center, center, nowrap);
			h1 {
				line-height: 1;
				color: $navy;
				margin: 0 0 15px;
			}
			h2 {
				color: $blue;
				margin: 0 0 25px;
			}
			p {
				color: $black;
			}
		}
	}
	@media screen and (max-width: 992px) {
		height: 900px;
	}
}