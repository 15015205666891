.template-home {
	#background {
		h2 {
			margin: 0 0 10px;
		}
		h3 {
			color: $blue;
			font-family: $font_copy;
			margin: 0 0 15px;
		}
	}
}