.slider {
	background: $white;
	.slidenav {
		color: $navy;
		&:hover, &:active, &:focus {
			color: $lblue;
		}
		&.uk-position-center-right-out {
			left: calc(100% - 25px);
		}
		&.uk-position-center-left-out {
			right: calc(100% - 25px);
		}
	}
	.uk-container {
		text-align: center;
		.uk-slider {
			ul {
				padding: 40px 25px 60px;
				overflow: hidden;
				li {
					width: calc(100% / 3);
					padding: 0 25px;
					min-width: 200px;
					.box {
						height: 100%;
						width: 100%;
						border-radius: $radius;
						overflow: hidden;
						position: relative;
						@extend %box-light;
						> div {
							width: 100% !important;
							height: 100%;
							border-radius: $radius;
							position: relative;
							background: $navy;
						}
						.background {
							position: absolute;
							top: 0; left: 0; right: 0; bottom: 0;
							width: 100%;
							height: 100%;
							z-index: 0;
							transition: transform 0.2s ease;
						}
						.copy {
							padding: 100% 0 0;
							width: 100% !important;
							height: 100%;
							position: relative;
							z-index: 1;
							@include flex(column, flex-end, flex-start, nowrap);
							h3 {
								display: none;
							}
						}
					}
					&:nth-child(odd) {
						.box {
							>div {
								background: $blue;
							}
						}
					}
					a {
						&:hover, &:active, &:focus {
							.background {
								transform: scale(1.02);
								transition: transform 0.2s ease;
							}
						}
					}
				}
			}
		}
	}
	& + .matrix {
		padding-top: 0;
	}
	&.noimg {
		.uk-container {
			.uk-slider {
				ul {
					li {
						.box {
							padding: 020px;
							background: $white;
							text-align: left;
							h3 {
								color: $navy;
								margin: 0;
							}
							.time, h4 {
								color: $blue;
								display: block;
								font-size: 0.8em;
								font-weight: bold;
								text-transform: uppercase;
								padding: 10px 0;
							}
							p {
								color: $black;
								font-size: 0.7em;
								line-height: 1.7;
								margin: 0;
							}
						}
						a {
							&:hover, &:active, &:focus {
								.box {
									background: rgba($ice, 0.4);
									
								}
							}
						}
					}
				}
			}
		}	
	}
	@media screen and (max-width: 992px) {
		&.noimg .uk-container {
			.uk-slider {
				ul li {
					width: 90%;
				}
			}
		}
	}
	@media screen and (max-width: 650px) {
		.slidenav {
			display: none;
		}
		.uk-container {
			.uk-slider {
				ul li {
					padding: 0 10px;
				}
			}
		}
	}
}